import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation login($email: String!) {
    login(input: { email: $email }) {
      status
      message
    }
  }
`;

export const VERIFY_USER = gql`
  mutation verifyUser($email: String!, $otp: String!) {
    verifyUser(input: { email: $email, otp: $otp }) {
      token
      user {
        id
        firstName
        lastName
        email
        profileImage
        refreshToken
        message
        lastLoggedIn
      }
    }
  }
`;

export const GET_NEW_TOKEN = gql`
  mutation tokenFromRefreshToken($refreshToken: String!) {
    tokenFromRefreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;
