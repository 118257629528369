import {
  BarChartOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterFilled,
  FilterOutlined,
  QrcodeOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Empty,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Switch,
  Table,
  Tag
} from 'antd';
import { map } from 'lodash';
import QRCode from 'qrcode.react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { TABLET_BREAKPOINT } from '../../common/constants';
import { copyToClipboard, downloadQR } from '../../common/utils';
import {
  CHANGE_LINK_STATUS,
  DELETE_LINK
} from '../../modules/Link/graphql/Mutations';
import {
  GET_ALL_LINKS,
  GET_LINKS_BYFOLDER,
  GET_TAGS_BY_WORKSPACE
} from '../../modules/Link/graphql/Queries';
import ShowMoreComponent from '../ShowMore/ShowMoreComponent';
import './commonLinkTable.less';

const CommonLinkTable = ({
  data = [],
  id,
  dataLoading,
  paginationConfig,
  filter,
  onChange,
  getLinks
}) => {
  let userdata = '';
  const history = useHistory();
  const {
    state: { filterData, windowWidth },
    getPermission,
    getWorkspace,
    dispatch
  } = useContext(AppContext);

  const permission = getPermission();
  const workspaceId = getWorkspace();

  const [isDesktop, setDesktop] = useState(windowWidth);
  const [isTablet, setIsTablet] = useState(false);

  const updateMedia = () => {
    // eslint-disable-next-line no-undef
    setDesktop(window?.innerWidth);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window?.removeEventListener('resize', updateMedia);
  });

  const [paginationProps, setPaginationProps] = useState({
    pageSizeOptions: [10, 15, 20, 50],
    defaultPageSize: 10,
    responsive: true,
    showSizeChanger: true,
    position: ['bottomCenter']
  });

  useEffect(() => {
    setPaginationProps({ ...paginationProps, ...paginationConfig });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  useEffect(() => {
    if (windowWidth < TABLET_BREAKPOINT) {
      setIsTablet(true);
    } else {
      setIsTablet(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modalVisible, setModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [changeStatusId, setChangeStatusId] = useState('');
  const [Status, setStatus] = useState('');
  const [link, setLink] = useState('');
  const [filterVisible, setFilterVisible] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [redirectLoading, setRedirectLoading] = useState(false);

  const [fetchTagsByWorkSpace] = useLazyQuery(GET_TAGS_BY_WORKSPACE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setTagList([...res?.getTagByWorkspace]);
      setFilterLoading(false);
    },
    onError() {}
  });

  const [deleteLink, { loading: deleteLoading }] = useMutation(DELETE_LINK, {
    onError() {}
  });
  const [redirect] = useMutation(CHANGE_LINK_STATUS, {
    onCompleted() {
      setRedirectLoading(false);
    },
    onError() {
      setRedirectLoading(false);
    }
  });

  useEffect(() => {
    if (filterData?.length > 0) {
      setSelectedTag([...filterData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterVisible) {
      setFilterLoading(true);
      fetchTagsByWorkSpace({
        variables: {
          workspaceId: workspaceId
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const deleteConfirm = () => {
    deleteLink({
      variables: { id: deleteId },
      refetchQueries: [
        filter
          ? {
              query: GET_ALL_LINKS,
              variables: {
                input: { workspaceId: id },
                filter: {
                  skip: 0,
                  limit: 10
                }
              }
            }
          : {
              query: GET_LINKS_BYFOLDER,
              variables: {
                input: { folderId: id },
                filter: {
                  skip: 0,
                  limit: 10
                }
              }
            }
      ]
    });
  };

  const handleStatusChange = (linkKey, linkStatus) => {
    setChangeStatusId(linkKey);
    setStatus(linkStatus === 'DISABLE' ? 'ENABLE' : 'DISABLE');
  };

  const changeStatusConfirm = () => {
    setRedirectLoading(true);
    redirect({
      variables: {
        id: changeStatusId,
        status: Status
      },
      refetchQueries: [
        filter
          ? {
              query: GET_ALL_LINKS,
              variables: {
                input: { workspaceId: id },
                filter: {
                  skip: 0,
                  limit: 10
                }
              }
            }
          : {
              query: GET_LINKS_BYFOLDER,
              variables: {
                input: { folderId: id },
                filter: {
                  skip: 0,
                  limit: 10
                }
              }
            }
      ]
    });
  };

  const handleClick = (shortLink) => {
    setLink(shortLink);
    setModalVisible(true);
  };

  const handleShortLinkRedirect = (
    redirectStatus,
    domain,
    domainStatus,
    shortLink
  ) => {
    if (redirectStatus === 'DISABLE') {
      message?.destroy();
      message?.error('Please enable this link');
    } else {
      const newLink =
        domainStatus === 'VERIFIED'
          ? `https://${domain}/${shortLink}`
          : `${process?.env?.REACT_APP_GRAPHQL}${shortLink}`;
      // eslint-disable-next-line no-undef
      window?.open(newLink, '_blank');
    }
  };

  userdata = data?.data?.map(
    ({
      id: linkId = '',
      shortLink = '',
      destinationLink = '',
      clicks = '',
      redirectStatus = '',
      createdAt = '',
      domainStatus,
      domain,
      tags
    }) => ({
      key: linkId,
      shortlink: (
        <span
          className={
            redirectStatus === 'DISABLE' ? 'disable-short-link' : 'short-link'
          }
          onClick={() =>
            handleShortLinkRedirect(
              redirectStatus,
              domain,
              domainStatus,
              shortLink
            )
          }
        >
          {shortLink}
        </span>
      ),
      destinationLink: destinationLink,
      clicks: clicks || 0,
      CreatedAt: createdAt,
      tags,
      redirectStatus,
      domain,
      shortLink,
      domainStatus: domainStatus,
      action: linkId
    })
  );

  const changeFilter = (e, tagId) => {
    const {
      target: { checked }
    } = e;
    if (checked) {
      setSelectedTag([...selectedTag, tagId]);
    } else {
      const filteredTagList = selectedTag?.filter((tag) => tag !== tagId);
      setSelectedTag([...filteredTagList]);
    }
  };

  const getFilterData = (confirm) => {
    dispatch({ type: 'SET_FILTER_TAG', data: selectedTag });

    const input = filter === 'all' ? { workspaceId: id } : { folderId: id };
    getLinks({
      variables: {
        input,
        filter: { skip: 0, limit: 10, tags: selectedTag }
      }
    });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters) => {
    const input = filter === 'all' ? { workspaceId: id } : { folderId: id };
    getLinks({
      variables: {
        input,
        filter: { skip: 0, limit: 10 }
      }
    });
    setSelectedTag([]);
    dispatch({ type: 'SET_FILTER_TAG', data: [] });
    clearFilters();
  };

  const handleDeleteFilter = (tagId) => {
    const filteredTagList = selectedTag?.filter((tag) => tag !== tagId);
    setSelectedTag([...filteredTagList]);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <Spin spinning={filterLoading}>
          {tagList?.length > 0 && (
            <div className="filter-section">
              {map(
                tagList?.filter((item) => selectedTag?.includes(item?.tag)),
                (item) => {
                  return (
                    <Tag
                      key={item?.toString()}
                      closable
                      onClose={() => handleDeleteFilter(item?.tag)}
                      className="filter-tag"
                    >
                      <span>{item?.tag}</span>
                    </Tag>
                  );
                }
              )}
            </div>
          )}
          <div className="filter-checkboxes">
            {tagList?.length > 0 ? (
              map(tagList, (item) => {
                return (
                  <div className="single-checkbox-div" key={item?.id}>
                    <Checkbox
                      value={item?.id}
                      checked={selectedTag?.includes(item?.tag)}
                      key={item?.id}
                      onChange={(e) => changeFilter(e, item?.tag)}
                      className="single-checkbox"
                    >
                      <span title={item?.tag}>{item?.tag}</span>
                    </Checkbox>
                  </div>
                );
              })
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </Spin>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="reset-button"
            id="roles-filter-reset"
            disabled={selectedTag?.length === 0}
            onClick={() => handleReset(clearFilters)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button ok-button"
            id="roles-filter-ok"
            type="primary"
            disabled={selectedTag?.length === 0}
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filterData?.length > 0 ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      setFilterVisible(visible);
    }
  });

  const handleCopy = (record) => {
    if (record?.domainStatus) {
      const linkToCopy = `${
        record?.domainStatus === 'VERIFIED'
          ? `https://${record?.domain}/`
          : process?.env?.REACT_APP_GRAPHQL
      }${record?.shortLink}`;
      copyToClipboard(linkToCopy);
      message.success('Copied.');
    }
  };

  const columns = [
    {
      title: 'Short link',
      dataIndex: 'shortlink',
      key: 'shortlink',
      className: 'max-width-column',
      fixed: 'left',
      width: isDesktop > 1300 ? 150 : 110,
      ...(userdata?.length !== 0
        ? {
            ...filterPopup('tags')
          }
        : {}),
      render: (shortLink, record) => {
        return (
          <div className="short-link-wrapper">
            <span>{shortLink}</span>&nbsp;
            <ShowMoreComponent show={2} data={record?.tags} />
          </div>
        );
      }
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      className: 'max-width-column',
      width: isDesktop > 1300 ? 120 : 100
    },
    {
      title: 'Destination link',
      dataIndex: 'destinationLink',
      key: 'destinationLink',
      ellipsis: true,
      className: 'max-width-column',
      width: isDesktop > 1300 ? 200 : ''
    },
    {
      title: 'Created on',
      dataIndex: 'CreatedAt',
      key: 'CreatedAt',
      className: 'max-width-column',
      width: isDesktop > 1300 ? 120 : 140
    },
    {
      title: 'Domain status',
      dataIndex: 'domainStatus',
      key: 'domainStatus',
      className: 'max-width-column',
      width: isDesktop > 1300 ? 200 : 130,
      render: (text) => (
        <div>
          {text && (
            <span>
              <Tag color={text === 'UNVERIFIED' ? 'error' : 'success'}>
                {text}
              </Tag>
            </span>
          )}
        </div>
      )
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      width: isDesktop > 1300 ? 250 : 95,
      className: 'max-width-column',
      render: (_, record) => {
        if (isTablet || userdata?.length === 1) {
          return (
            <Space size={isDesktop > 1300 ? 'middle' : 'small'}>
              <Button
                className="btn_link"
                onClick={() => history?.push(`/${record?.key}/analytics`)}
                type="link"
              >
                <BarChartOutlined title="Analytics" />
              </Button>

              <Button
                className="btn_link"
                onClick={() => handleClick(record?.shortLink)}
                type="link"
                disabled={record?.redirectStatus === 'DISABLE'}
              >
                <QrcodeOutlined title="QR Code" />
              </Button>

              <Button
                onClick={() => handleCopy(record)}
                disabled={record?.redirectStatus === 'DISABLE'}
                className="btn_link"
                type="link"
              >
                <CopyOutlined title="Copy to Clipboard" />
              </Button>

              <Button
                className="btn_link"
                disabled={permission === 'READ_ONLY'}
                onClick={() => history?.push(`/link/${record?.key}/edit`)}
                type="link"
              >
                <EditOutlined title="Edit" />
              </Button>

              <Popconfirm
                disabled={permission === 'READ_ONLY'}
                title="Are you sure？"
                onConfirm={deleteConfirm}
                okText="Yes"
                cancelText="No"
                icon={<QuestionCircleOutlined className="red_col" />}
              >
                <Button
                  onClick={() => setDeleteId(record?.key)}
                  type="link"
                  loading={deleteLoading && record?.key === deleteId}
                  danger
                  disabled={permission === 'READ_ONLY'}
                >
                  <DeleteOutlined title="Delete" />
                </Button>
              </Popconfirm>
              <Popconfirm
                disabled={permission === 'READ_ONLY'}
                title="Are you sure？"
                onConfirm={changeStatusConfirm}
                okText="Yes"
                cancelText="No"
                icon={<QuestionCircleOutlined />}
              >
                <Switch
                  checked={record?.redirectStatus === 'ENABLE'}
                  loading={
                    record?.key === changeStatusId ? redirectLoading : false
                  }
                  className="disable-icon-switch"
                  disabled={permission === 'READ_ONLY'}
                  onChange={() =>
                    handleStatusChange(record?.key, record?.redirectStatus)
                  }
                />
              </Popconfirm>
            </Space>
          );
        }
        if (record?.key === currentRecord) {
          return (
            <Space size={isDesktop > 1300 ? 'middle' : 'small'}>
              <Button
                className="btn_link"
                onClick={() => history?.push(`/${record?.key}/analytics`)}
                type="link"
              >
                <BarChartOutlined title="Analytics" />
              </Button>
              <Button
                className="btn_link"
                onClick={() => handleClick(record?.shortLink)}
                type="link"
                disabled={record?.redirectStatus === 'DISABLE'}
              >
                <QrcodeOutlined title="QR Code" />
              </Button>
              <Button
                onClick={() => handleCopy(record)}
                disabled={record?.redirectStatus === 'DISABLE'}
                className="btn_link"
                type="link"
              >
                <CopyOutlined title="Copy to Clipboard" />
              </Button>
              <Button
                className="btn_link"
                disabled={permission === 'READ_ONLY'}
                onClick={() => history?.push(`/link/${record?.key}/edit`)}
                type="link"
              >
                <EditOutlined title="Edit" />
              </Button>
              <Popconfirm
                disabled={permission === 'READ_ONLY'}
                title="Are you sure？"
                onConfirm={deleteConfirm}
                okText="Yes"
                cancelText="No"
                icon={<QuestionCircleOutlined className="red_col" />}
              >
                <Button
                  onClick={() => setDeleteId(record?.key)}
                  type="link"
                  loading={deleteLoading && record?.key === deleteId}
                  danger
                  disabled={permission === 'READ_ONLY'}
                >
                  <DeleteOutlined title="Delete" />
                </Button>
              </Popconfirm>
              <Popconfirm
                disabled={permission === 'READ_ONLY'}
                title="Are you sure？"
                onConfirm={changeStatusConfirm}
                okText="Yes"
                cancelText="No"
                icon={<QuestionCircleOutlined />}
              >
                <Switch
                  loading={
                    record?.key === changeStatusId ? redirectLoading : false
                  }
                  className="disable-icon-switch"
                  checked={record?.redirectStatus === 'ENABLE'}
                  disabled={permission === 'READ_ONLY'}
                  onChange={() =>
                    handleStatusChange(record?.key, record?.redirectStatus)
                  }
                />
              </Popconfirm>
            </Space>
          );
        }
      }
    }
  ];

  const onOk = (canvasId) => {
    downloadQR(canvasId);
  };
  return (
    <>
      <Table
        className="mt-20 common-link-table"
        dataSource={userdata}
        columns={columns}
        loading={dataLoading}
        pagination={paginationConfig ? paginationProps : false}
        onChange={onChange}
        rowKey={(obj) => obj?.id}
        {...(data?.data?.length
          ? {
              scroll: {
                x: 'max-content'
              }
            }
          : {})}
        onRow={(record) => {
          return {
            onMouseEnter: () => {
              setCurrentRecord(record?.key);
            }
          };
        }}
      />
      <Modal
        title="QR Code"
        centered
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={
          <div className="d-flex justify-end">
            <Button onClick={() => setModalVisible(false)}>Cancel</Button>
            <Button type="primary" onClick={() => onOk(link)}>
              Download
            </Button>
          </div>
        }
        cancelButtonProps={{ className: 'btn-secondary-xl' }}
      >
        <Row justify="center">
          <QRCode
            id={link}
            value={`${process?.env?.REACT_APP_GRAPHQL}${link}`}
          />
        </Row>
      </Modal>
    </>
  );
};

export default CommonLinkTable;
