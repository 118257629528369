import { gql } from '@apollo/client';

export const CREATE_LINK = gql`
  mutation createLink($input: linkInput!) {
    createLink(input: $input) {
      shortLink
      message
    }
  }
`;

export const CREATE_QUICK_LINK = gql`
  mutation createQuickLink($input: quickLinkInput!) {
    createQuickLink(input: $input) {
      shortLink
      message
    }
  }
`;

export const VERIFY_DOMAIN = gql`
  mutation verifyDomain($input: VerifyDomainInput!) {
    verifyDomain(input: $input) {
      domain
      domainStatus
      message
    }
  }
`;

export const DELETE_LINK = gql`
  mutation deleteLink($id: ID!) {
    deleteLink(id: $id) {
      status
      message
    }
  }
`;

export const UPDATE_LINK = gql`
  mutation updateLink($input: updateLinkInput!) {
    updateLink(input: $input) {
      shortLink
      message
    }
  }
`;

export const CHANGE_LINK_STATUS = gql`
  mutation redirect($id: ID!, $status: Status!) {
    redirect(input: { id: $id, status: $status }) {
      message
    }
  }
`;

export const CREATE_TAGS = gql`
  mutation createTag($input: CreateTagInput!) {
    createTag(input: $input) {
      tag
    }
  }
`;

export const GET_SIGNED_URL = gql`
  mutation getSignedUrl($fileName: String!, $fileType: String!) {
    getSignedUrl(fileName: $fileName, fileType: $fileType) {
      signedURL
      fileURL
    }
  }
`;
