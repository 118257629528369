import { gql } from '@apollo/client';

export const GET_WORKSPACE_DETAILS = gql`
  query workspace($filter: CommonFilter!) {
    workspace(filter: $filter) {
      count
      data {
        id
        name
        logo
        archived
        defaultFolder
        permission
        user {
          id
          firstName
          lastName
          email
          profileImage
        }
      }
    }
  }
`;

export const GET_WORKSPACE_DETAILS_BYID = gql`
  query getWorkspaceDetails($workspaceId: ID!) {
    getWorkspaceById(workspaceId: $workspaceId) {
      id
      name
      logo
      permission
      archived
    }
  }
`;
export const GET_WORKSPACE_INVITE = gql`
  query workspaceInvite($filter: CommonFilter!) {
    workspaceInvite(filter: $filter) {
      count
      data {
        id
        name
        logo
        ownerId
      }
    }
  }
`;

export const GET_FOLDER_LIST = gql`
  query getfolderlist($input: FoldersInput!, $filter: CommonFilter!) {
    folder(input: $input, filter: $filter) {
      count
      data {
        id
        folderName
        workspaceId
        createdBy {
          firstName
          lastName
          email
          profileImage
        }
      }
      message
    }
  }
`;
export const GET_FOLDER_DETAIL = gql`
  query getFolderById($folderId: ID!) {
    getFolderById(folderId: $folderId) {
      folder {
        id
        folderName
        workspaceId
        createdBy {
          firstName
          lastName
          email
          profileImage
        }
      }
    }
  }
`;

export const GET_ALL_MEMBERS = gql`
  query getMemberByWorkspaceId(
    $input: GetMemberByWorkspaceIdInput!
    $filter: CommonFilter!
  ) {
    getMemberByWorkspaceId(input: $input, filter: $filter) {
      count
      data {
        firstName
        email
        status
        permission
        memberId
        inviteId
        lastName
        workspaceId
      }
    }
  }
`;

export const GET_MEMBER_DETAILS = gql`
  query getMemberByMemberId($memberId: ID!, $workspaceId: ID!) {
    getMemberByMemberId(
      input: { memberId: $memberId, workspaceId: $workspaceId }
    ) {
      memberId
      inviteId
      firstName
      lastName
      email
      status
      permission
      workspaceId
      workspaceName
    }
  }
`;

export const GET_INVITE_DETAILS = gql`
  query getInviteByInviteId($inviteId: ID!) {
    getInviteByInviteId(input: { inviteId: $inviteId }) {
      memberId
      inviteId
      firstName
      lastName
      email
      status
      permission
      workspaceId
      workspaceName
    }
  }
`;
