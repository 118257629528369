import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import UserProfile from './UserProfile';

const UserWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.USERS_PROFILE} component={UserProfile} />
    </Switch>
  );
};

export default UserWrapper;
