import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AppContext } from './AppContext';

function PublicRoute({ component: Component, ...rest }) {
  const { getToken, getOtpStatus } = useContext(AppContext);
  const idToken = getToken();
  const hasOtp = getOtpStatus();

  return (
    <Route
      {...rest}
      render={(props) =>
        idToken && !hasOtp ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
}

export default PublicRoute;
