import { gql } from '@apollo/client';

export const CREATE_WORKSPACE = gql`
  mutation createWorkspace($name: String!, $logo: String) {
    createWorkspace(input: { name: $name, logo: $logo }) {
      workspace {
        id
        name
        logo
        defaultFolder
        permission
        user {
          firstName
          lastName
          email
          profileImage
          message
        }
      }
      message
    }
  }
`;

export const UPDATE_WORKSPACE = gql`
  mutation updateWorkspace($id: ID!, $name: String, $logo: String) {
    updateWorkspace(input: { id: $id, name: $name, logo: $logo }) {
      workspace {
        id
        name
        logo
      }
      message
    }
  }
`;

export const ARCHIVE_WORKSPACE = gql`
  mutation archiveWorkspace($workspaceId: ID!) {
    archiveWorkspace(input: { workspaceId: $workspaceId }) {
      status
      message
    }
  }
`;

export const UNARCHIVE_WORKSPACE = gql`
  mutation unarchiveWorkspace($workspaceId: ID!) {
    unarchiveWorkspace(input: { workspaceId: $workspaceId }) {
      status
      message
    }
  }
`;

export const DELETE_WORKSPACE = gql`
  mutation deleteWorkspace($workspaceId: ID!) {
    deleteWorkspace(input: { workspaceId: $workspaceId }) {
      status
      message
    }
  }
`;

export const INVITE_MEMBER = gql`
  mutation inviteMember(
    $email: String!
    $workspaceId: ID!
    $permission: Permission
  ) {
    inviteMember(
      input: {
        email: $email
        workspaceId: $workspaceId
        permission: $permission
      }
    ) {
      status
      message
    }
  }
`;

export const RESEND_INVITE_MEMBER = gql`
  mutation resendInvite(
    $email: String!
    $workspaceId: ID!
    $permission: Permission
  ) {
    resendInvite(
      input: {
        email: $email
        workspaceId: $workspaceId
        permission: $permission
      }
    ) {
      status
      message
    }
  }
`;

export const VERIFY_MEMBER = gql`
  mutation verifyMember(
    $workspaceId: ID!
    $ownerId: ID!
    $inviteStatus: InviteStatus!
  ) {
    verifyMember(
      input: {
        ownerId: $ownerId
        workspaceId: $workspaceId
        inviteStatus: $inviteStatus
      }
    ) {
      status
      message
    }
  }
`;

export const CREATE_FOLDER = gql`
  mutation createFolder($folderName: String!, $workspaceId: ID!) {
    createFolder(
      input: { folderName: $folderName, workspaceId: $workspaceId }
    ) {
      message
      folder {
        id
        folderName
        workspaceId
        createdBy {
          firstName
          lastName
          email
          profileImage
        }
      }
    }
  }
`;

export const UPDATE_FOLDER = gql`
  mutation updateFolder($folderId: ID!, $folderName: String!) {
    updateFolder(input: { folderName: $folderName, folderId: $folderId }) {
      message
      folderName
    }
  }
`;

export const DELETE_FOLDER = gql`
  mutation deleteFolder($folderId: ID!) {
    deleteFolder(input: { folderId: $folderId }) {
      message
    }
  }
`;

export const UPDATE_MEMBER_PERMISSION = gql`
  mutation editMember(
    $memberId: ID!
    $workspaceId: ID!
    $permission: Permission!
  ) {
    editMember(
      input: {
        memberId: $memberId
        workspaceId: $workspaceId
        permission: $permission
      }
    ) {
      message
    }
  }
`;

export const UPDATE_INVITE_PERMISSION = gql`
  mutation editInvite($inviteId: ID!, $permission: Permission!) {
    editInvite(input: { inviteId: $inviteId, permission: $permission }) {
      message
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation deleteMember($memberId: ID!, $workspaceId: ID!) {
    deleteMember(input: { memberId: $memberId, workspaceId: $workspaceId }) {
      message
    }
  }
`;

export const DELETE_INVITE = gql`
  mutation deleteInvite($inviteId: ID!) {
    deleteInvite(inviteId: $inviteId) {
      message
    }
  }
`;
