import { useLazyQuery } from '@apollo/client';
import { Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AppContext } from './AppContext';
import { GET_USER_DETAILS } from './modules/users/graphql/Queries';
import { GET_WORKSPACE_DETAILS_BYID } from './modules/workspace/graphql/Queries';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { initializeAuth, getToken, getWorkspace, setPermission } = useContext(
    AppContext
  );
  const idToken = getToken();
  const id = getWorkspace();
  const [fetchUserLoading, setFetchUserLoading] = useState(false);

  const [getUserData] = useLazyQuery(GET_USER_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const { user } = res;
      initializeAuth(idToken, user);
      setFetchUserLoading(false);
    },
    onError() {
      setFetchUserLoading(false);
    }
  });

  const [getWorkspaceById, { loading }] = useLazyQuery(
    GET_WORKSPACE_DETAILS_BYID,
    {
      onCompleted(spacedata) {
        const { getWorkspaceById: { permission = '' } = {} } = spacedata;
        setPermission(permission);
      },
      onError() {}
    }
  );
  useEffect(() => {
    if (id) {
      getWorkspaceById({
        variables: {
          workspaceId: id
        }
      });
    }
    if (idToken) {
      setFetchUserLoading(false);
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || fetchUserLoading)
    return (
      <div className="center-element mt-20p">
        <Spin size="large" />
      </div>
    );

  return (
    <Route
      {...rest}
      render={(props) =>
        !idToken ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  );
};
export default PrivateRoute;
