import { Popover, Tag } from 'antd';
import { map, slice } from 'lodash';
import React from 'react';
import './ShowMore.less';

const ShowMoreComponent = (props) => {
  const { data = [], show = 1, placement = 'rightBottom' } = props;

  const showItems = slice(data, 0, show);
  const count = data?.length;
  const restItems = slice(data, show, count);
  const content = (
    <div>
      {restItems.map((item) => (
        <Tag
          className="single-item"
          title={item?.label || item}
          key={item?.id || item}
          color="magenta"
        >
          <span className="content-text">
            {item?.label || item?.name || item}
          </span>
        </Tag>
      ))}
    </div>
  );

  return (
    <div className="show-more-component">
      {map(showItems, (item) => {
        return (
          <Tag
            className="single-item"
            title={item?.label || item?.name || item}
            key={item?.id || item}
          >
            <span className="content-text">
              {item?.label || item?.name || item}
            </span>
          </Tag>
        );
      })}
      {count > show && (
        <Popover
          overlayClassName="custom-popover"
          placement={placement}
          content={content}
        >
          <Tag className="single-item pointer more-shadow">
            <span className="content-text">{count - show} more</span>
          </Tag>
        </Popover>
      )}
    </div>
  );
};

export default ShowMoreComponent;
