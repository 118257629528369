import { UserOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Col, Modal, Row, Spin, Tabs, Tag } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import welcomeCheckmark from '../../assets/welcome-checkmark.svg';
import welcomeCloseImg from '../../assets/welcome-close.svg';
import welcomeImg from '../../assets/welcome.svg';
import { ROUTES } from '../../common/constants';
import { LIMIT } from '../../common/utils';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import WorkspaceHeader from '../../components/WorkspaceHeader/WorkspaceHeader';
import { VERIFY_MEMBER } from './graphql/Mutations';
import { GET_WORKSPACE_DETAILS, GET_WORKSPACE_INVITE } from './graphql/Queries';
import Workspace from './Workspace';

const { TabPane } = Tabs;
const SelectWorkspace = () => {
  const history = useHistory();
  const {
    setWorkspace,
    getCurrentUser,
    setPermission,
    dispatch,
    state: { currentUser }
  } = useContext(AppContext);

  const { userId } = getCurrentUser();
  const [selectedID, setSelectedID] = useState({
    WorkspaceID: '',
    folderID: '',
    permission: ''
  });
  const [acceptedID, setAcceptedID] = useState('');
  const [skip, setSkip] = useState(0);
  const [inviteSkip, setInviteSkip] = useState(0);
  const [count, setCount] = useState(0);
  const [activeTab, setActiveTab] = useState('WORKSPACE');
  const [inviteCount, setInviteCount] = useState(0);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [invitedData, setInvitedData] = useState([]);
  const [declineID, setDeclineID] = useState('');
  const [loading, setLoading] = useState(true);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [isLoggedInBefore, setIsLoggedInBefore] = useState(
    currentUser?.lastLoggedIn
  );

  const [getWorkSpaceDetail] = useLazyQuery(GET_WORKSPACE_DETAILS, {
    onCompleted(res) {
      setLoading(true);
      setCount(res?.workspace?.count);
      setWorkspaceData([...res?.workspace?.data]);
      setLoading(false);
    },
    fetchPolicy: 'network-only'
  });

  const [getInviteWorkSpace] = useLazyQuery(GET_WORKSPACE_INVITE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setInviteLoading(true);
      setInviteCount(res?.workspaceInvite?.count);
      setInvitedData([...res?.workspaceInvite?.data]);
      setInviteLoading(false);
    }
  });

  useEffect(() => {
    getWorkSpaceDetail({
      variables: {
        filter: {
          skip: skip,
          limit: LIMIT
        }
      }
    });
    getInviteWorkSpace({
      variables: {
        filter: {
          skip: inviteSkip,
          limit: LIMIT
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      selectedID?.folderID &&
      selectedID?.WorkspaceID &&
      selectedID?.permission
    ) {
      setPermission(selectedID?.permission);
      setWorkspace(selectedID?.WorkspaceID);
      dispatch({ type: 'SET_FILTER_TAG', data: [] });
      history?.push(`/${selectedID?.folderID}${ROUTES?.FOLDER}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedID]);

  const [verifyMember, { loading: verifyLoading }] = useMutation(
    VERIFY_MEMBER,
    {
      onCompleted: () => {
        getInviteWorkSpace({
          variables: {
            filter: {
              skip: inviteSkip,
              limit: LIMIT
            }
          }
        });
        getWorkSpaceDetail({
          variables: {
            filter: {
              skip: skip,
              limit: LIMIT
            }
          }
        });
      },
      onError() {}
    }
  );

  const handleAccept = ({ workspaceId, ownerId }) => {
    verifyMember({
      variables: {
        ownerId: ownerId,
        workspaceId: workspaceId,
        inviteStatus: 'ACCEPT'
      }
    });
    setAcceptedID(workspaceId);
  };
  const handleDecline = ({ workspaceId, ownerId }) => {
    verifyMember({
      variables: {
        ownerId: ownerId,
        workspaceId: workspaceId,
        inviteStatus: 'DECLINE'
      }
    });
    setDeclineID(workspaceId);
  };

  const handleInfiniteScroll = (e) => {
    const element = e?.target;
    if (
      element?.scrollTop + element?.offsetHeight >= element?.scrollHeight - 5 &&
      skip + LIMIT < count &&
      activeTab === 'WORKSPACE'
    ) {
      setSkip(skip + LIMIT);
    } else if (
      element?.scrollTop + element?.offsetHeight >= element?.scrollHeight - 5 &&
      inviteSkip + LIMIT < inviteCount &&
      activeTab === 'INVITED_WORKSPACE'
    ) {
      setInviteSkip(inviteSkip + LIMIT);
    }
  };

  useEffect(() => {
    if (workspaceData?.length || invitedData?.length) {
      // eslint-disable-next-line no-undef
      document
        ?.querySelector('.tab-menu .ant-tabs-content-holder')
        ?.addEventListener('scroll', handleInfiniteScroll);
      return () => {
        // eslint-disable-next-line no-undef
        document
          ?.querySelector('.tab-menu .ant-tabs-content-holder')
          ?.removeEventListener('scroll', handleInfiniteScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData, invitedData, activeTab]);

  return (
    <div className="workspace-list">
      <div className="fixed-header">
        <WorkspaceHeader />
        <Workspace hide />
      </div>
      {!isLoggedInBefore && (
        <Modal
          visible={!isLoggedInBefore}
          footer={null}
          className="welcome-model"
          closeIcon={
            <img
              height={20}
              onClick={() => setIsLoggedInBefore(true)}
              width={20}
              src={welcomeCloseImg}
              alt="close"
            />
          }
        >
          <div className="d-flex align-center justify-center mt-30">
            <div className="d-flex flex-vertical align-center header">
              <img
                src={welcomeImg}
                alt="welcome-popper"
                height={75}
                width={75}
              />
              <span className="title-text">Welcome to Utags</span>
            </div>
          </div>
          <div className="welcome-message d-flex flex-vertical justify-between align-start">
            <div className="d-flex align-center">
              <img src={welcomeCheckmark} alt="checkmark" />
              <span className="points">
                Transform your links into effective tools for marketing!
              </span>
            </div>
            <div className="d-flex align-center mt-10">
              <img src={welcomeCheckmark} alt="checkmark" />
              <span className="points">
                Shorten and customise | Track in real time
              </span>
            </div>
            <div className="d-flex align-center mt-10">
              <img src={welcomeCheckmark} alt="checkmark" />
              <span className="points">Get started in 3 easy steps:</span>
            </div>
            <ul>
              <li>Add a new URL</li>
              <li>Customise it with URL Tags</li>
              <li>Start analyzing your clicks</li>
            </ul>
          </div>
        </Modal>
      )}
      <Spin
        spinning={loading || inviteLoading}
        size="large"
        className="center-element"
      >
        <Tabs
          activeKey={activeTab}
          defaultActiveKey="WORKSPACE"
          centered
          className="tab-menu"
          onChange={(e) => setActiveTab(e)}
        >
          <TabPane tab="Your Workspace" key="WORKSPACE">
            {workspaceData
              ?.filter(
                (record) =>
                  userId === parseInt(record?.user?.id, 10) ||
                  (userId !== parseInt(record?.user?.id, 10) &&
                    !record.archived)
              )
              .map(
                ({
                  id = '',
                  name = '',
                  logo = '',
                  defaultFolder = '',
                  permission = '',
                  user = ''
                }) => (
                  <div className="site-card-wrapper-workspace" key={id}>
                    <Row>
                      <Col>
                        <Card
                          hoverable
                          onClick={() => {
                            setWorkspace(id);
                            setSelectedID({
                              folderID: defaultFolder,
                              WorkspaceID: id,
                              permission: permission
                            });
                          }}
                          bordered
                          className="cardworkspace"
                        >
                          <div>
                            {logo ? (
                              <img
                                className="profile-image-70"
                                src={logo}
                                alt="avatar"
                              />
                            ) : (
                              <Avatar size={70} icon={<UserOutlined />} />
                            )}
                          </div>
                          <div className="mt-10 workspace-name">{name} </div>
                          <div className="mt-10 workspace-name">
                            {userId !== parseInt(user?.id, 10) ? (
                              <Tag color="processing">Member</Tag>
                            ) : (
                              <Tag color="success">Owner</Tag>
                            )}
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                )
              )}
          </TabPane>
          <TabPane tab="Invited Workspace" key="INVITED_WORKSPACE">
            {invitedData?.map(
              ({ id = '', name = '', logo = '', ownerId = '' }) => (
                <div className="site-card-wrapper-workspace" key={id}>
                  <Row>
                    <Col>
                      <Card hoverable bordered className="cardworkspace">
                        <div>
                          {logo ? (
                            <img
                              className="profile-image-70"
                              src={logo}
                              alt="avatar"
                            />
                          ) : (
                            <Avatar size={70} icon={<UserOutlined />} />
                          )}
                        </div>
                        <div className="mt-10 workspace-name">{name}</div>
                        <Row
                          justify="space-around"
                          align="middle"
                          className="mt-20"
                        >
                          <Col>
                            <Button
                              onClick={() =>
                                handleAccept({
                                  workspaceId: id,
                                  ownerId: ownerId
                                })
                              }
                              type="primary"
                              htmlType="submit"
                              loading={verifyLoading && id === acceptedID}
                              size="small"
                              className=" btn-secondary"
                            >
                              Accept
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              size="small"
                              type="primary"
                              className="btn-small "
                              onClick={() =>
                                handleDecline({
                                  workspaceId: id,
                                  ownerId: ownerId
                                })
                              }
                              loading={verifyLoading && id === declineID}
                            >
                              Decline
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </div>
              )
            )}
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  );
};

export default SelectWorkspace;
