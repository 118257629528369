import { gql } from '@apollo/client';

export const GET_CLICKS = gql`
  query getClicks($linkId: ID!, $startDate: String, $endDate: String) {
    getClicks(
      input: { linkId: $linkId, startDate: $startDate, endDate: $endDate }
    ) {
      clicks
      monthWise {
        clicks
        month
      }
    }
  }
`;
export const GET_BROWSER = gql`
  query getBrowsers($linkId: ID!, $startDate: String, $endDate: String) {
    getBrowsers(
      input: { linkId: $linkId, startDate: $startDate, endDate: $endDate }
    ) {
      count
      totalCount
      browser {
        browser
        count
      }
    }
  }
`;

export const GET_OS = gql`
  query getOperatingSystem($linkId: ID!, $startDate: String, $endDate: String) {
    getOperatingSystem(
      input: { linkId: $linkId, startDate: $startDate, endDate: $endDate }
    ) {
      count
      totalCount
      operatingSystem {
        os
        count
      }
    }
  }
`;

export const GET_DEVICE = gql`
  query getDevices($linkId: ID!, $startDate: String, $endDate: String) {
    getDevices(
      input: { linkId: $linkId, startDate: $startDate, endDate: $endDate }
    ) {
      count
      totalCount
      devices {
        device_count
        device
        platform
        platform_count
      }
    }
  }
`;

export const GET_REGION = gql`
  query getCountry($linkId: ID!, $startDate: String, $endDate: String) {
    getCountry(
      input: { linkId: $linkId, startDate: $startDate, endDate: $endDate }
    ) {
      count
      totalCount
      country {
        country
        count
        countryCode
      }
    }
  }
`;
