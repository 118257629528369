import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  QuestionCircleOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Badge, Button, Col, Popconfirm, Row, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { initialPaginationValue, initialTableFilter } from '../../common/utils';
import Card from '../../components/Card/card';
import { DELETE_INVITE, DELETE_MEMBER } from './graphql/Mutations';
import { GET_ALL_MEMBERS, GET_WORKSPACE_DETAILS_BYID } from './graphql/Queries';
import InviteMember from './InviteMember';

const PERMISSION_OBJ = {
  READ_ONLY: 'Read Only',
  WRITE: 'Write'
};

const WorkspaceMember = (props) => {
  let userdata = '';
  const history = useHistory();
  const { id } = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [Status, setStatus] = useState('');
  const [workspaceID, setWorkspaceID] = useState('');
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [userFilter, setUserFilter] = useState(initialTableFilter);

  const [getMemberList, { data = {}, loading, refetch }] = useLazyQuery(
    GET_ALL_MEMBERS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: 10,
          total: res?.getMemberByWorkspaceId?.count
        };
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );
  useEffect(() => {
    getMemberList({
      variables: {
        input: { workspaceId: id },
        filter: userFilter
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { data: workspaceData = {}, loading: worskspaceLoading } = useQuery(
    GET_WORKSPACE_DETAILS_BYID,
    {
      variables: { workspaceId: id },
      fetchPolicy: 'network-only'
    }
  );
  const [deleteMember, { loading: mdeleteLoading }] = useMutation(
    DELETE_MEMBER,
    {
      onError() {}
    }
  );
  const [deleteInvite, { loading: ideleteLoading }] = useMutation(
    DELETE_INVITE,
    {
      onError() {}
    }
  );

  const deleteConfirm = async () => {
    if (Status === 'ACCEPTED') {
      const response = await deleteMember({
        variables: {
          memberId: deleteId,
          workspaceId: workspaceID
        }
      });
      if (response) {
        getMemberList({
          variables: {
            input: { workspaceId: id },
            filter: userFilter
          }
        });
      }
    } else {
      const response = await deleteInvite({
        variables: { inviteId: deleteId }
      });
      if (response) {
        getMemberList({
          variables: {
            input: { workspaceId: id },
            filter: userFilter
          }
        });
      }
    }
  };

  const handleClick = ({ selectedid, status, workspaceId }) => {
    setDeleteId(selectedid);
    setStatus(status);
    setWorkspaceID(workspaceId);
  };

  const getStatusBadge = (status) => {
    if (status === 'ACCEPTED') {
      return <Badge status="success" text={status} />;
    }
    if (status === 'PENDING') {
      return <Badge status="warning" text={status} />;
    }
    return <Badge status="error" text={status} />;
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Permission',
      dataIndex: 'permission',
      key: 'permission',
      render: (permission) => {
        return <span>{PERMISSION_OBJ[permission]}</span>;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => getStatusBadge(text)
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 80
    }
  ];
  const { getWorkspaceById: { name = '', archived = '' } = {} } = workspaceData;
  const { getMemberByWorkspaceId: { data: user = [] } = {} } = data;

  const handleEditClick = ({ memberId, status, workspaceId, inviteId }) => {
    props?.history?.push({
      pathname: `member/${memberId || inviteId}/edit`,
      status: status,
      workspaceId: workspaceId
    });
  };

  userdata = user?.map(
    ({
      firstName = '',
      email = '',
      status = '',
      permission = '',
      memberId = '',
      workspaceId = '',
      inviteId = ''
    }) => ({
      key: email,
      name: firstName || '-',
      email: email,
      permission: permission,
      status: status,
      action: (
        <Space size="middle">
          {status !== 'REJECTED' && (
            <Button
              className="btn_link"
              onClick={() =>
                handleEditClick({
                  memberId: memberId,
                  status: status,
                  workspaceId: workspaceId,
                  inviteId: inviteId
                })
              }
              type="link"
              disabled={archived}
            >
              <EditOutlined title="Edit" />
            </Button>
          )}
          {status === 'REJECTED' && (
            <Button
              className="btn_link"
              onClick={() =>
                handleEditClick({
                  memberId: memberId,
                  status: status,
                  workspaceId: workspaceId,
                  inviteId: inviteId
                })
              }
              type="link"
              disabled={archived}
            >
              <UserAddOutlined title="Re-invite" />
            </Button>
          )}
          <Popconfirm
            title="Are you sure？"
            onConfirm={deleteConfirm}
            okText="Yes"
            cancelText="No"
            icon={<QuestionCircleOutlined className="red_col" />}
            disabled={archived}
          >
            <Button
              onClick={() =>
                handleClick({
                  selectedid: memberId || inviteId,
                  status: status,
                  workspaceId: workspaceId
                })
              }
              type="link"
              loading={
                (mdeleteLoading || ideleteLoading) &&
                (memberId || inviteId) === deleteId
              }
              danger
              disabled={archived}
            >
              <DeleteOutlined title="Delete" />
            </Button>
          </Popconfirm>
        </Space>
      )
    })
  );

  const changeVisibility = () => {
    setModalVisible(false);
    refetch();
  };
  const [paginationProps, setPaginationProps] = useState({
    pageSizeOptions: [10, 15, 20, 50],
    defaultPageSize: 10,
    responsive: true,
    showSizeChanger: true,
    position: ['bottomCenter']
  });

  useEffect(() => {
    setPaginationProps({ ...paginationProps, ...paginationProp });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationProp]);

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setUserFilter({ ...userFilter, skip: skip, limit: pagination?.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });
    setUserFilter({
      ...userFilter
    });
    getMemberList({
      variables: {
        input: { workspaceId: id },
        filter: {
          ...userFilter,
          skip,
          limit: pagination?.pageSize
        }
      }
    });
  };
  return (
    <>
      <div hidden={modalVisible}>
        <Card
          className="mb-20 member-table"
          loading={worskspaceLoading}
          title={
            <>
              <Row justify="space-between" align="middle">
                <Col>
                  <Button
                    onClick={() => history?.goBack()}
                    className="btn-back"
                    type="text"
                  >
                    <LeftOutlined title="Back" />
                  </Button>
                  <span className="ml-5"> {name}</span>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => setModalVisible(true)}
                    disabled={archived}
                  >
                    Invite member
                  </Button>
                </Col>
              </Row>
            </>
          }
        >
          <Table
            className="mt-10"
            dataSource={userdata}
            columns={columns}
            loading={loading}
            onChange={handleTableChange}
            pagination={paginationProps || false}
          />
        </Card>
      </div>
      <div hidden={!modalVisible}>
        <InviteMember workspaceId={id} visible={changeVisibility} />
      </div>
    </>
  );
};

export default WorkspaceMember;
