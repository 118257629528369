import { useLazyQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { initialPaginationValue, initialTableFilter } from '../../common/utils';
import Card from '../../components/Card/card';
import CommonLinkTable from '../../components/commonLinkTable/CommonLinkTable';
import { GET_ALL_LINKS } from '../Link/graphql/Queries';
import './dashboard.less';

const Dashboard = () => {
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [userFilter, setUserFilter] = useState(initialTableFilter);

  const { id } = useParams();
  const {
    state: { filterData },
    setWorkspace
  } = useContext(AppContext);

  useEffect(() => {
    setWorkspace(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [getAllLinks, { data = {}, loading: dataLoading }] = useLazyQuery(
    GET_ALL_LINKS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: 10,
          total: res?.getLinkByWorkspaceId?.count
        };
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );

  useEffect(() => {
    getAllLinks({
      variables: {
        input: { workspaceId: id },
        filter: { ...userFilter, tags: filterData }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getLinkByWorkspaceId = [] } = data;

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setUserFilter({ ...userFilter, skip: skip, limit: pagination?.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });

    setUserFilter({
      ...userFilter
    });
    getAllLinks({
      variables: {
        input: { workspaceId: id },
        filter: {
          ...userFilter,
          skip,
          limit: pagination?.pageSize,
          tags: filterData
        }
      }
    });
  };

  return (
    <>
      <Card>
        <Row justify="space-between" align="middle">
          <Col className="create-link">All links</Col>
        </Row>
      </Card>

      <CommonLinkTable
        data={getLinkByWorkspaceId}
        id={id}
        dataLoading={dataLoading}
        filter="all"
        paginationConfig={paginationProp}
        onChange={handleTableChange}
        getLinks={getAllLinks}
      />
    </>
  );
};

export default Dashboard;
