import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../common/constants';
import Error404 from '../Error404';
import Analytics from '../modules/Analytics/Analytics';
import DashboardWrapper from '../modules/Dashboard';
import Dashboard from '../modules/Dashboard/Dashboard';
import EditLink from '../modules/Link/EditLink';
import LinkListing from '../modules/Link/LinkListing';
import UserWrapper from '../modules/users';
import InviteMember from '../modules/workspace/InviteMember';
import MemberUpdate from '../modules/workspace/MemberUpdate';
import Workspace from '../modules/workspace/Workspace';
import WorkspaceList from '../modules/workspace/WorkspaceList';
import WorkspaceMember from '../modules/workspace/WorkspaceMember';

const ContentRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact path={ROUTES?.USERS_PROFILE} component={UserWrapper} />
        <Route exact path={ROUTES?.MAIN} component={DashboardWrapper} />
        <Route exact path={ROUTES?.WORKSPACE} component={WorkspaceList} />
        <Route exact path={ROUTES?.INVITEMEMBER} component={InviteMember} />
        <Route
          exact
          path={`${ROUTES?.WORKSPACE}/:id${ROUTES?.EDIT}`}
          component={Workspace}
        />
        <Route
          exact
          path={`${ROUTES?.WORKSPACE}/:id/members`}
          component={WorkspaceMember}
        />
        <Route
          exact
          path={`${ROUTES?.WORKSPACE}/:id/member/:id/edit`}
          component={MemberUpdate}
        />
        <Route exact path={`/:id${ROUTES?.WORKSPACE}`} component={Dashboard} />
        <Route exact path={`/:id${ROUTES?.FOLDER}`} component={LinkListing} />
        <Route
          exact
          path={`${ROUTES?.LINK}/:id${ROUTES?.EDIT}`}
          component={EditLink}
        />
        <Route exact path={`/:id${ROUTES?.ANALYTICS}`} component={Analytics} />
        <Route path="*" exact component={Error404} />
      </Switch>
    </>
  );
};

export default ContentRoutes;
