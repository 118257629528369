import { gql } from '@apollo/client';

export const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $firstName: String
    $lastName: String
    $profileImage: String
  ) {
    updateUser(
      input: {
        profileImage: $profileImage
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      id
      firstName
      lastName
      email
      profileImage
      refreshToken
      message
      lastLoggedIn
    }
  }
`;

export const GET_SIGNED_URL = gql`
  mutation getSignedUrl($fileName: String!, $fileType: String!) {
    getSignedUrl(fileName: $fileName, fileType: $fileType) {
      signedURL
      fileURL
    }
  }
`;
