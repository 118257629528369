import { LeftOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Col, DatePicker, Row, Space, Typography } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { defaultDateFormat } from '../../common/constants';
import Card from '../../components/Card/card';
import { GET_LINKS_BYID } from '../Link/graphql/Queries';
import AnalyticsCard from './AnalyticsCard';
import BrowserData from './BrowserData';
import ClicksChart from './ClicksChart';
import DeviceData from './DeviceData';
import OsData from './OsData';
import RegionData from './RegionData';

const { Text } = Typography;

const { RangePicker } = DatePicker;

const Analytics = () => {
  const { id } = useParams();
  const history = useHistory();
  const { setStartDate, setEndDate } = useContext(AppContext);

  useEffect(() => {
    setStartDate('');
    setEndDate('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data = {}, loading } = useQuery(GET_LINKS_BYID, {
    variables: {
      linkId: id
    },
    fetchPolicy: 'network-only'
  });

  const { getLinkById: { shortLink = '', destinationLink = '' } = {} } = data;

  const handlechange = (_, date) => {
    setStartDate(date?.[0]);
    setEndDate(date?.[1]);
  };

  return (
    <>
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <Button
              onClick={() => history?.goBack()}
              className="btn-back"
              type="text"
            >
              <LeftOutlined title="Back" className="orange_col fontsize-18" />
              <span className="create-link"> Analytics </span>
            </Button>
          </Col>
          <Col>
            <Space direction="vertical" size={10}>
              <RangePicker onChange={handlechange} format={defaultDateFormat} />
            </Space>
          </Col>
        </Row>
      </Card>

      <Card loading={loading} className="mt-30">
        <Row justify="start">
          <Col className="create-link">
            <a
              href={`${process?.env?.REACT_APP_GRAPHQL}${shortLink}`}
              target="blank"
            >{`${shortLink}`}</a>
          </Col>
        </Row>
        <Row justify="start" className="mt-5">
          <Col className="align-justify">
            <Text>{destinationLink}</Text>
          </Col>
        </Row>
        <AnalyticsCard />
      </Card>
      <div className="grid">
        <div>
          <ClicksChart hidden />
        </div>
        <div>
          <RegionData hidden hide show />
        </div>
      </div>
      <div className="mt-30">
        <div className="grid">
          <div>
            <BrowserData hidden />
            <div className="mt-30">
              <DeviceData hidden />
            </div>
          </div>
          <div>
            <OsData hidden />
            <div className="mt-30">
              <RegionData hidden />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;
