import { Card as AntCard } from 'antd';
import { React } from 'react';
import './styles.less';

const Card = ({
  bordered,
  bottom,
  children,
  height,
  padding,
  size,
  style,
  title,
  className,
  extra,
  grid,
  actions,
  onClick,
  cover,
  key,
  hoverable,
  loading
}) => {
  return (
    <AntCard
      key={key}
      onClick={onClick}
      cover={cover}
      grid={grid}
      hoverable={hoverable}
      actions={actions}
      extra={extra}
      size={size}
      title={title}
      bordered={bordered}
      height={height}
      style={style}
      padding={padding}
      bottom={bottom}
      className={className}
      loading={loading}
    >
      {children}
    </AntCard>
  );
};
export default Card;
