import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Avatar,
  Col,
  Descriptions,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { WORKSPACE } from '../../common/constants';
import { initialPaginationValue, initialTableFilter } from '../../common/utils';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import {
  ARCHIVE_WORKSPACE,
  DELETE_WORKSPACE,
  UNARCHIVE_WORKSPACE
} from './graphql/Mutations';
import { GET_WORKSPACE_DETAILS } from './graphql/Queries';
import InviteMember from './InviteMember';

const WorkspaceList = () => {
  let userdata = '';
  const history = useHistory();
  const { getCurrentUser, getWorkspace } = useContext(AppContext);
  const { userId } = getCurrentUser();
  const workspaceId = getWorkspace();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [userFilter, setUserFilter] = useState(initialTableFilter);
  const [archiveId, setArchiveId] = useState('');
  const [unarchiveId, setUnArchiveId] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const [
    getWorkspaceData,
    { data: workspacedata = {}, loading }
  ] = useLazyQuery(GET_WORKSPACE_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.workspace?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });
  useEffect(() => {
    getWorkspaceData({
      variables: {
        filter: userFilter
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [archiveWorkspace, { loading: archiveLoading }] = useMutation(
    ARCHIVE_WORKSPACE,
    {
      onError() {}
    }
  );
  const [unarchiveWorkspace, { loading: unarchiveLoading }] = useMutation(
    UNARCHIVE_WORKSPACE
  );
  const [deleteWorkspace, { loading: deleteLoading }] = useMutation(
    DELETE_WORKSPACE,
    {
      onCompleted() {
        if (workspaceId === deleteId) {
          // eslint-disable-next-line no-undef
          localStorage?.removeItem(WORKSPACE);
          history?.push('/');
        }
      },
      onError() {}
    }
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <div>
          {text.logo ? (
            <img className="profile-image-40" src={text.logo} alt="avatar" />
          ) : (
            <Avatar size={40} icon={<UserOutlined />} />
          )}
          <span className="ml-10">{text.text}</span>
          <span className="ml-10">
            {text.archive ? <Tag color="warning">Archived</Tag> : ''}
          </span>
        </div>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 100
    }
  ];

  async function archiveConfirm() {
    const response = await archiveWorkspace({
      variables: { workspaceId: archiveId }
    });
    if (response) {
      getWorkspaceData({
        variables: {
          filter: userFilter
        }
      });
    }
  }
  async function unarchiveConfirm() {
    const response = await unarchiveWorkspace({
      variables: { workspaceId: unarchiveId }
    });
    if (response) {
      getWorkspaceData({
        variables: {
          filter: userFilter
        }
      });
    }
  }
  async function deleteConfirm() {
    const response = await deleteWorkspace({
      variables: { workspaceId: deleteId }
    });
    if (response) {
      getWorkspaceData({
        variables: {
          filter: userFilter
        }
      });
    }
  }

  const changeVisibility = () => {
    setModalVisible(false);
  };

  const { workspace = [] } = workspacedata;
  if (workspace) {
    userdata = workspace?.data
      ?.filter(
        (record) =>
          userId === parseInt(record?.user?.id, 10) ||
          (userId !== parseInt(record?.user?.id, 10) && !record.archived)
      )
      .map(({ id = '', name = '', logo = '', archived = '', user = {} }) => ({
        key: id,
        name: { text: name, archive: archived, logo: logo },
        user: user,

        action: (
          <Space size="middle">
            <Button
              className="btn_link"
              onClick={() => history?.push(`workspace/${id}/edit`)}
              type="link"
              disabled={userId !== parseInt(user?.id, 10)}
            >
              <EditOutlined title="Edit" />
            </Button>
            <Popconfirm
              title="Are you sure？"
              onConfirm={deleteConfirm}
              okText="Yes"
              cancelText="No"
              disabled={userId !== parseInt(user?.id, 10)}
              icon={<QuestionCircleOutlined className="red_col" />}
            >
              <Button
                onClick={() => setDeleteId(id)}
                type="link"
                loading={deleteLoading && id === deleteId}
                danger
                disabled={userId !== parseInt(user?.id, 10)}
              >
                <DeleteOutlined title="Delete" />
              </Button>
            </Popconfirm>
            {archived ? (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={unarchiveConfirm}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  className="btn_link"
                  id={id}
                  onClick={() => setUnArchiveId(id)}
                  type="link"
                  loading={unarchiveLoading && id === unarchiveId}
                >
                  <UploadOutlined title="Unarchive" />
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={archiveConfirm}
                okText="Yes"
                cancelText="No"
                disabled={userId !== parseInt(user?.id, 10)}
              >
                <Button
                  className="btn_link"
                  id={id}
                  onClick={() => setArchiveId(id)}
                  type="link"
                  loading={archiveLoading && id === archiveId}
                  disabled={userId !== parseInt(user?.id, 10)}
                >
                  <DownloadOutlined title="Archive" />
                </Button>
              </Popconfirm>
            )}
            <Button
              onClick={() => history?.push(`workspace/${id}/members`)}
              type="link"
              disabled={userId !== parseInt(user?.id, 10)}
            >
              <TeamOutlined title="Members" />
            </Button>
          </Space>
        )
      }));
  }
  const [paginationProps, setPaginationProps] = useState({
    pageSizeOptions: [10, 15, 20, 50],
    defaultPageSize: 10,
    responsive: true,
    showSizeChanger: true,
    position: ['bottomCenter']
  });

  useEffect(() => {
    setPaginationProps({ ...paginationProps, ...paginationProp });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationProp]);

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setUserFilter({ ...userFilter, skip: skip, limit: pagination?.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });
    setUserFilter({
      ...userFilter
    });
    getWorkspaceData({
      variables: {
        filter: {
          ...userFilter,
          skip,
          limit: pagination?.pageSize
        }
      }
    });
  };
  return (
    <>
      <div hidden={modalVisible}>
        <Row justify="center">
          <Col lg={15} md={18}>
            <Card
              className="mb-20"
              title={
                <>
                  <Row justify="space-between" align="middle">
                    <Col> Manage Workspace</Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => setModalVisible(true)}
                      >
                        Invite member
                      </Button>
                    </Col>
                  </Row>
                </>
              }
            >
              <div>
                <Table
                  className="mt-10"
                  dataSource={userdata}
                  columns={columns}
                  pagination={paginationProps || false}
                  loading={loading}
                  onChange={handleTableChange}
                  expandable={{
                    expandedRowRender: (record) => (
                      <Descriptions
                        title="Owner's Info"
                        bordered
                        column={2}
                        contentStyle={{ backgroundColor: 'white' }}
                      >
                        <Descriptions.Item label="Name">
                          {`${record?.user?.firstName || '-'} ${
                            record?.user?.lastName || '-'
                          }`}
                        </Descriptions.Item>
                        <Descriptions.Item label="Profile Picture">
                          {record?.user?.profileImage ? (
                            <img
                              className="profile-image-40"
                              src={record?.user?.profileImage}
                              alt="avatar"
                            />
                          ) : (
                            <Avatar size={40} icon={<UserOutlined />} />
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="email" span={1}>
                          {record?.user?.email}
                        </Descriptions.Item>
                        <Descriptions.Item label="" span={1} />
                      </Descriptions>
                    )
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <div hidden={!modalVisible}>
        <InviteMember visible={changeVisibility} />
      </div>
    </>
  );
};

export default WorkspaceList;
