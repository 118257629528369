import { useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import CommonForm from '../../components/commonForm/CommonForm';
import { LOGIN } from './graphql/Mutations';

const Login = (props) => {
  const { dispatch } = useContext(AppContext);

  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted() {
      props?.history?.replace({
        pathname: ROUTES?.VERIFY_USER
      });
    },
    onError() {}
  });

  const onFinish = (values) => {
    const { email } = values;
    dispatch({ type: 'SET_USER_EMAIL', data: email });
    login({
      variables: {
        email
      }
    });
  };
  return (
    <>
      <CommonForm btnText="Login" onFinish={onFinish} loading={loading} />
    </>
  );
};

export default Login;
