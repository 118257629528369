import {
  CaretDownOutlined,
  MenuOutlined,
  MoreOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Avatar,
  Button,
  Col,
  Drawer,
  Layout,
  Menu,
  Popover,
  Row,
  Spin
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import Logo from '../../../assets/urlTags.svg';
import { MODULES, ROUTES } from '../../../common/constants';
import Folders from '../../../modules/workspace/Folders';
import { GET_WORKSPACE_DETAILS_BYID } from '../../../modules/workspace/graphql/Queries';
import Workspace from '../../../modules/workspace/Workspace';

const { Header } = Layout;
const { SubMenu } = Menu;

const AppHeader = ({ location: { pathname } }) => {
  const history = useHistory();
  const { getWorkspace, getCurrentUser } = useContext(AppContext);
  const id = getWorkspace();
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState(false);
  const [workSpaceDetailLoading, setWorkSpaceDetailLoading] = useState(true);
  const [workSpaceName, setWorkSpaceName] = useState(null);
  const [selectedKey, setSelectedKey] = useState(['SELECTED_WORKSPACE']);
  const { firstName, lastName, profileImage } = getCurrentUser();

  const [getData] = useLazyQuery(GET_WORKSPACE_DETAILS_BYID, {
    onCompleted(res) {
      const { getWorkspaceById: { name = '' } = {} } = res;
      setWorkSpaceName(name);
      setWorkSpaceDetailLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {}
  });

  useEffect(() => {
    setWorkSpaceDetailLoading(true);
    getData({
      variables: {
        workspaceId: id
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (pathname?.split('/')[1] === 'workspace') {
      setSelectedKey([ROUTES?.WORKSPACE]);
    } else if (pathname?.includes('folder')) {
      setSelectedKey([pathname]);
    } else if (pathname?.includes('profile')) {
      setSelectedKey([pathname]);
    } else {
      setSelectedKey([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onChange = (text) => {
    setVisible(!visible);
    setPlacement(text);
  };

  const onMenuSelect = (e) => {
    if (e === 'SELECTED_WORKSPACE') {
      setSelectedKey('SELECTED_WORKSPACE');
      return;
    }
    setSelectedKey(e.key);
  };

  const avatarContent = (
    <Menu className="profile-popover">
      <Menu.Item onClick={() => history?.push(ROUTES?.LOGOUT)}>
        Logout
      </Menu.Item>
      <Menu.Item onClick={() => history?.push(ROUTES?.USERS_PROFILE)}>
        {MODULES?.USERS_MANAGEMENT}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Header>
        <div className="gx-search-bar gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg">
          <div className="d-flex align-center">
            <Row>
              <Col>
                <Button
                  onClick={() => onChange('left')}
                  className="btn-back fontsize-22 hamburger"
                  type="text"
                >
                  <MenuOutlined />
                </Button>
              </Col>
              <Col />
            </Row>

            <Link to="/" className="gx-site-logo ml-10 center-logo">
              <Row align="middle">
                <Col span={4}>
                  <img
                    className="logo-icon"
                    src={Logo}
                    alt="Put your logo here"
                  />
                </Col>
              </Row>
            </Link>
          </div>
        </div>
        <Row>
          <Col>
            <Button
              onClick={() => onChange('right')}
              className="btn-back fontsize-22 hamburger"
              type="text"
            >
              <MoreOutlined />
            </Button>
          </Col>
        </Row>
        <div className="header-notification header-menu">
          <Menu
            mode="horizontal"
            selectedKeys={selectedKey}
            triggerSubMenuAction="click"
            onSelect={onMenuSelect}
            className="header-menu"
          >
            <Menu.Item
              key={ROUTES?.WORKSPACE}
              onClick={() => history?.push(ROUTES?.WORKSPACE)}
            >
              {MODULES?.WORKSPACE_MANAGEMENT}
            </Menu.Item>
            <SubMenu
              key="SELECTED_WORKSPACE"
              title={
                <Row justify="space-between" align="middle">
                  <Col>
                    {workSpaceName || 'Select Workspace'}
                    <span className="ml-10 create-link">
                      <CaretDownOutlined setSelectedKey={setSelectedKey} />
                    </span>
                  </Col>
                </Row>
              }
            >
              <Spin spinning={workSpaceDetailLoading}>
                <Workspace hidden Buttontype="link" />
              </Spin>
            </SubMenu>
            <Menu.Item key={ROUTES?.USERS_PROFILE}>
              <Popover
                overlayClassName="profile-popover"
                content={avatarContent}
              >
                {profileImage && <Avatar src={profileImage} alt="profile" />}
                {firstName && lastName && !profileImage && (
                  <Avatar>
                    {firstName?.charAt(0)?.toUpperCase()}
                    {lastName?.charAt(0)?.toUpperCase()}
                  </Avatar>
                )}
                {!firstName && !lastName && !profileImage && (
                  <Avatar icon={<UserOutlined />} />
                )}
              </Popover>
            </Menu.Item>
          </Menu>
        </div>
      </Header>

      <Drawer
        placement={placement}
        closable
        onClose={onChange}
        visible={visible}
        title={
          <Link to="/" className="gx-site-logo">
            <Row align="middle">
              <Col span={4}>
                <img
                  className="logo-icon"
                  src={Logo}
                  alt="Put your logo here"
                />
              </Col>
            </Row>
          </Link>
        }
      >
        {placement === 'left' ? (
          <Folders />
        ) : (
          <div className="header-notification  ">
            <Menu theme="lite" className="sidebar-menu">
              <Menu.Item
                className="d-flex align-center mt-30 mb-0"
                onClick={() => history?.push(ROUTES?.WORKSPACE)}
              >
                <span className="sub-title fontsize-18">
                  {MODULES?.WORKSPACE_MANAGEMENT}
                </span>
              </Menu.Item>
              <Menu.Item
                className="d-flex align-center"
                onClick={() => history?.push(ROUTES?.USERS_PROFILE)}
              >
                <span className="sub-title fontsize-18">
                  {MODULES?.USERS_MANAGEMENT}
                </span>
              </Menu.Item>
              <SubMenu
                className="d-flex align-center"
                key="SubMenu"
                title={
                  <Row justify="space-between" align="middle">
                    <Col>
                      <span className="sub-title fontsize-18">
                        {workSpaceName || 'Select Workspace'}
                      </span>
                      <span className="ml-10 create-link">
                        <CaretDownOutlined />
                      </span>
                    </Col>
                  </Row>
                }
              >
                <Spin spinning={workSpaceDetailLoading} className="pl-50 ">
                  <Workspace hidden Buttontype="link" />
                </Spin>
              </SubMenu>
              <Menu.Item className="d-flex align-center ">
                <span className="sub-title fontsize-18">
                  <Link to={ROUTES?.LOGOUT}>Logout</Link>
                </span>
              </Menu.Item>
            </Menu>
          </div>
        )}
      </Drawer>
    </>
  );
};
export default withRouter(AppHeader);
