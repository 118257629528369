import { Form, Input, Spin } from 'antd';
import React from 'react';

const CommonFolderForm = ({
  inputChange,
  data,
  loading,
  form,
  onFinish,
  setDisableButton
}) => {
  return (
    <Spin size="large" spinning={loading}>
      <Form
        onFinish={onFinish}
        form={form}
        name="basic"
        initialValues={{
          remember: true
        }}
        layout="vertical"
        onValuesChange={() => setDisableButton(false)}
      >
        <Form.Item
          className="mb-0"
          label="Folder Name"
          initialValue={data}
          name="foldername"
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Folder Name is required'
            }
          ]}
        >
          <Input placeholder="Folder Name" onChange={inputChange} />
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default CommonFolderForm;
