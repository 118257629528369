import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { REGEX } from '../../common/constants';
import {
  copyToClipboard,
  initialPaginationValue,
  initialTableFilter
} from '../../common/utils';
import Card from '../../components/Card/card';
import CommonLinkTable from '../../components/commonLinkTable/CommonLinkTable';
import { GET_WORKSPACE_DETAILS_BYID } from '../workspace/graphql/Queries';
import { CREATE_LINK } from './graphql/Mutations';
import { GET_LINKS_BYFOLDER } from './graphql/Queries';

const LinkListing = () => {
  const {
    state: { filterData },
    getWorkspace,
    getPermission
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const { id } = useParams();
  const workspaceId = getWorkspace();
  const permission = getPermission();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [userFilter, setUserFilter] = useState(initialTableFilter);
  const [modalVisible, setModalVisible] = useState(false);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [URL, setURL] = useState('');

  const { loading: workspaceLoading, data: workspaceData } = useQuery(
    GET_WORKSPACE_DETAILS_BYID,
    {
      variables: {
        workspaceId
      }
    }
  );

  useEffect(() => {
    form?.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  const [createLink, { loading }] = useMutation(CREATE_LINK, {
    onError() {}
  });

  const [getLinks, { data = {}, loading: dataLoading }] = useLazyQuery(
    GET_LINKS_BYFOLDER,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: 10,
          total: res?.getLinkByFolderId?.count
        };
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );

  useEffect(() => {
    getLinks({
      variables: {
        input: { folderId: id },
        filter: { ...userFilter, tags: filterData }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setUserFilter({ ...userFilter, skip: skip, limit: pagination?.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });
    setUserFilter({
      ...userFilter
    });
    getLinks({
      variables: {
        input: { folderId: id },
        filter: {
          ...userFilter,
          skip,
          limit: pagination?.pageSize,
          tags: filterData
        }
      }
    });
  };

  const onFinish = async (values) => {
    if (URL) {
      setModalVisible(false);
      getLinks({
        variables: {
          input: { folderId: id },
          filter: { ...userFilter, tags: filterData }
        }
      });
      setURL(null);
      return;
    }
    const {
      url,
      utmCampaign,
      utmSource,
      utmMedium,
      utmTerm,
      utmContent
    } = values;
    const input = {
      URL: url,
      utmCampaign,
      utmSource,
      utmMedium,
      utmTerm,
      utmContent,
      folderId: id,
      workspaceId: workspaceId
    };
    const response = await createLink({
      variables: {
        input
      }
    });
    setPaginationProp(initialPaginationValue);
    if (response) {
      const { shortLink } = response?.data?.createLink;
      setURL(shortLink);
    }
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const onCancel = () => {
    if (URL) {
      getLinks({
        variables: {
          input: { folderId: id },
          filter: {
            skip: 0,
            limit: 10,
            tags: filterData
          }
        }
      });
      setURL(null);
    }
    setModalVisible(false);
  };

  const { getLinkByFolderId = [] } = data;

  return (
    <>
      <Card>
        <Row justify="space-between" align="middle">
          <Col className="create-link">Create a new link</Col>
          <Col>
            <Button
              disabled={
                permission === 'READ_ONLY' ||
                workspaceData?.getWorkspaceById?.archived
              }
              type="primary"
              htmlType="submit"
              onClick={() => setModalVisible(true)}
            >
              Create a new link
            </Button>
          </Col>
        </Row>
      </Card>
      <Modal
        title="Create a new link"
        centered
        visible={modalVisible}
        onOk={form?.submit}
        onCancel={() => onCancel()}
        className="add-link-modal"
        auto
        okButtonProps={{
          loading: loading,
          className: 'btn-Primary'
        }}
        cancelButtonProps={{ className: 'btn-secondary-xl' }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
          validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Enter URL"
            name="url"
            rules={[
              {
                required: true,
                pattern: REGEX?.WEB_URL,
                message: 'Enter valid URL'
              }
            ]}
          >
            <Input placeholder="https://yourlink.com" />
          </Form.Item>
          <Form.Item label="Campaign" name="utmCampaign">
            <Input placeholder="Campaign" />
          </Form.Item>
          <Form.Item label="Medium" name="utmMedium">
            <Input placeholder="Medium" />
          </Form.Item>

          <Form.Item label="Source" name="utmSource">
            <Input placeholder="Source" />
          </Form.Item>
          <Form.Item label="Content" name="utmContent">
            <Input placeholder="Content" />
          </Form.Item>

          <Form.Item label="Term" name="utmTerm" className="mb-0">
            <Input placeholder="Term" />
          </Form.Item>
          {URL && (
            <div className="mt-20">
              <span className="d-flex justify-center align-center shorten-url-labels ">
                Copy below link
              </span>
              <div className="shorten-url-section d-flex justify-between align-center mt-10">
                <span>
                  <a
                    href={`${process?.env?.REACT_APP_GRAPHQL}${URL}`}
                    target="blank"
                  >{`${process?.env?.REACT_APP_GRAPHQL}${URL}`}</a>
                </span>
                <Button
                  onClick={() => {
                    copyToClipboard(`${process?.env?.REACT_APP_GRAPHQL}${URL}`);
                    message?.success('Link copied to clipboard..!');
                  }}
                  type="primary"
                >
                  Copy
                </Button>
              </div>
            </div>
          )}
        </Form>
      </Modal>
      <CommonLinkTable
        data={getLinkByFolderId}
        id={id}
        dataLoading={dataLoading || workspaceLoading}
        paginationConfig={paginationProp}
        onChange={handleTableChange}
        getLinks={getLinks}
      />
    </>
  );
};

export default LinkListing;
