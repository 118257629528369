import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_LINKS_BYFOLDER = gql`
  query getLinkByFolderId(
    $input: GetLinkByFolderInput!
    $filter: FilterWithTag!
  ) {
    getLinkByFolderId(input: $input, filter: $filter) {
      count
      data {
        id
        shortLink
        destinationLink
        domain
        domainStatus
        clicks
        createdAt
        utmCampaign
        utmSource
        utmMedium
        utmTerm
        utmContent
        tags
        folderId
        workspaceId
        redirectStatus
      }
    }
  }
`;
export const GET_ALL_LINKS = gql`
  query getLinkByWorkspaceId(
    $input: GetLinkByWorkspaceInput!
    $filter: FilterWithTag!
  ) {
    getLinkByWorkspaceId(input: $input, filter: $filter) {
      count
      data {
        id
        shortLink
        destinationLink
        domain
        domainStatus
        clicks
        createdAt
        utmCampaign
        utmSource
        utmMedium
        utmTerm
        utmContent
        tags
        folderId
        workspaceId
        redirectStatus
      }
    }
  }
`;

export const GET_LINKS_BYID = gql`
  query getLinkById($linkId: ID!) {
    getLinkById(linkId: $linkId) {
      id
      shortLink
      domain
      domainStatus
      destinationLink
      utmCampaign
      utmSource
      utmMedium
      utmTerm
      utmContent
      tags
      folderId
      workspaceId
      redirectStatus
      notes
      ogTitle
      ogDescription
      ogImage
    }
  }
`;

export const GET_TAGS = gql`
  query getTag {
    getTag {
      id
      tag
      createdBy
      message
    }
  }
`;

export const GET_TAGS_BY_WORKSPACE = gql`
  query getTagByWorkspace($workspaceId: ID!) {
    getTagByWorkspace(workspaceId: $workspaceId) {
      id
      tag
    }
  }
`;
