import { LeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Card,
  Col,
  Form,
  Input,
  message,
  Popover,
  Row,
  Select,
  Spin,
  Typography,
  Upload
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ALLOW_IMAGE_TYPES } from '../../common/constants';
import Button from '../../components/Button/Button';
import {
  CREATE_TAGS,
  GET_SIGNED_URL,
  UPDATE_LINK,
  VERIFY_DOMAIN
} from './graphql/Mutations';
import { GET_LINKS_BYID, GET_TAGS } from './graphql/Queries';
import './LinkModule.less';

const { Option } = Select;
const { Text } = Typography;
const { Dragger } = Upload;
const { Meta } = Card;

const EditLink = () => {
  const [form] = Form?.useForm();
  const { id } = useParams();
  const history = useHistory();
  const [domainData, setDomainData] = useState();
  const { getWorkspace } = useContext(AppContext);
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const [title, setTitle] = useState('');
  const workspaceId = getWorkspace();
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [isfileChanged, setIsfileChanged] = useState(false);
  const [validationTriggered, setValidationTriggered] = useState(false);

  const [getLinksById] = useLazyQuery(GET_LINKS_BYID, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const ogImage = res?.getLinkById?.ogImage
        ? [{ url: res?.getLinkById?.ogImage, title: res?.getLinkById?.ogTitle }]
        : [];

      setFileList(ogImage);

      const initialValueCopy = {
        ...res?.getLinkById,
        slug: res?.getLinkById?.shortLink,
        tags: res?.getLinkById?.tags?.length > 0 ? res?.getLinkById?.tags : []
      };
      setInitialValues(initialValueCopy);
      setLoading(false);
      setDomainData(res?.getLinkById?.domain);
      setImageUrl(res?.getLinkById?.ogImage);
      setTitle(res?.getLinkById?.ogTitle ? res?.getLinkById?.ogTitle : 'Title');
      setDescription(
        res?.getLinkById?.ogDescription
          ? res?.getLinkById?.ogDescription
          : 'Description'
      );
    },
    onError() {}
  });

  useEffect(() => {
    getLinksById({
      variables: {
        linkId: id
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: tagsData = {}, loading: tagsLoading } = useQuery(GET_TAGS, {
    fetchPolicy: 'network-only'
  });

  const [updateLink, { loading: updateLoading }] = useMutation(UPDATE_LINK, {
    onCompleted() {
      setIsfileChanged(false);
      history?.goBack();
    },
    onError() {}
  });

  const [createTag, { loading: createtagLoading }] = useMutation(CREATE_TAGS);

  const [uploadFile] = useMutation(GET_SIGNED_URL, {
    onError: () => {}
  });

  const [verifyDomain] = useMutation(VERIFY_DOMAIN, {
    onError() {}
  });

  if (loading || tagsLoading)
    return (
      <div className="center-element">
        <Spin size="large" />
      </div>
    );

  const { getTag = [] } = tagsData;

  const CreateTags = (val) => {
    createTag({
      variables: { input: { tag: val, workspaceId } }
    });
  };

  const onFinish = async (props) => {
    const {
      utmCampaign = '',
      utmContent = '',
      utmMedium = '',
      slug = '',
      utmSource = '',
      tags = [],
      utmTerm = '',
      notes = '',
      domain = '',
      ogTitle = '',
      ogDescription = '',
      ogImage
    } = props;
    let metaImgUrl = '';

    if (isfileChanged && fileList?.length > 0) {
      const { name, type } = ogImage;
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      const timestamp = Date?.now();
      const newname = name?.split('.')?.slice(0, -1)?.join('.');
      const newFilename = `${timestamp}_${newname}.${ext}`;
      const res = await uploadFile({
        variables: {
          fileName: newFilename,
          fileType: type
        }
      });

      if (res?.data?.getSignedUrl) {
        const { signedURL, fileURL } = res?.data?.getSignedUrl;

        const options = {
          headers: {
            'Content-Type': type,
            'Access-Control-Allow-Origin': 'Origin',
            'Access-Control-Allow-Methods': 'PUT',
            'x-amz-acl': 'public-read'
          }
        };
        try {
          const response = await axios.put(signedURL, ogImage, options);
          if (response) {
            metaImgUrl = fileURL;
          }
        } catch (error) {
          message?.error('Error while uploading file or image!');
        }
      }
    }

    const input = {
      id,
      slug,
      utmCampaign,
      utmSource,
      utmMedium,
      utmTerm,
      utmContent,
      tags,
      notes,
      domain,
      ogTitle,
      ogDescription,
      ogImage: isfileChanged ? metaImgUrl : ogImage
    };

    updateLink({
      variables: {
        input
      }
    });
    tags?.forEach(CreateTags);
  };

  const handleVerify = () => {
    verifyDomain({
      variables: {
        input: {
          domain: form?.getFieldValue('domain'),
          workspaceId: workspaceId
        }
      }
    });
  };

  const onChangeUpload = (info) => {
    const {
      file: { name = '', url },
      fileList: imageList
    } = info;

    setIsfileChanged(true);
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    if (imageList?.length > 0) {
      if (ALLOW_IMAGE_TYPES?.includes(ext) && !url) {
        const metaUrl = URL?.createObjectURL(info?.file);
        if (info?.file?.status === 'removed') {
          form?.setFieldsValue({
            ogImage: null
          });
          setImageUrl(null);
        } else {
          form?.setFieldsValue({
            ogImage: info?.file
          });
          setImageUrl(metaUrl);
        }
        setFileList([...info?.fileList]);
      } else {
        setFileList([]);
        form?.setFieldsValue({
          ogImage: null
        });
        message?.destroy();
        message?.error(`${info?.file?.name} file is not image file.`);
      }
    } else {
      setFileList([]);
      setImageUrl(null);
    }
  };

  const handleTitleChange = (e) => {
    const {
      target: { value }
    } = e;
    setTitle(value);
  };

  const handleDescriptionChange = (e) => {
    const {
      target: { value }
    } = e;
    setDescription(value);
  };

  const MetaSectionTitle = (
    <div className="title-section">
      <div className="title">
        <span>Add Meta Tag</span>
      </div>
      <div className="title">
        <span>Preview</span>
      </div>
    </div>
  );

  const content = (
    <div className="domain-description">
      <p>
        Custom Domain can be used if you want to replace default utags domain
        with your own domain
      </p>
    </div>
  );

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  return (
    <>
      <Card className="mb-10">
        <Row justify="space-between" align="middle">
          <Col>
            <Button
              onClick={() => history?.goBack()}
              className="btn-back"
              type="text"
            >
              <LeftOutlined title="Back" />
            </Button>
            <span className="ml-5 create-link"> Update link </span>
          </Col>
        </Row>
      </Card>

      <Card loading={loading} className=" mb-20">
        <Row>
          <Col className="create-link">
            {initialValues?.redirectStatus === 'DISABLE' ? (
              <span
                onClick={() => message?.error('Please enable this link')}
                className="disable-create-link "
              >
                {initialValues?.shortLink}
              </span>
            ) : (
              <a
                href={`${process?.env?.REACT_APP_GRAPHQL}${initialValues?.shortLink}`}
                target="blank"
              >{`${initialValues?.shortLink}`}</a>
            )}
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="align-justify">
            <Text>{initialValues?.destinationLink}</Text>
          </Col>
        </Row>
      </Card>

      <Form
        name="tag-form"
        initialValues={initialValues}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        scrollToFirstError
        form={form}
      >
        <Row gutter={10}>
          <Col span={12}>
            <Card className="mb-20" title="Choose domain name">
              <Row gutter={10}>
                <Col xs={16} sm={16} md={16} lg={16} xl={18} xxl={18}>
                  <div className="d-flex mb-10 domain-info">
                    <span>
                      Domain Name
                      <Popover
                        placement="topLeft"
                        overlayClassName="domain-popover"
                        content={content}
                      >
                        <QuestionCircleOutlined className="ml-10" />
                      </Popover>
                    </span>
                  </div>
                  <Form.Item name="domain">
                    <Input
                      placeholder="urltags.com"
                      onChange={(e) => setDomainData(e?.target?.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Button
                    htmlType="button"
                    className="btn-secondary-xl verify-button"
                    onClick={handleVerify}
                    disabled={
                      initialValues?.domainStatus === 'VERIFIED' &&
                      initialValues?.domain === domainData
                    }
                  >
                    Verify
                  </Button>
                </Col>
              </Row>

              <Form.Item
                label="Slug"
                name="slug"
                rules={[{ required: true, len: 8 }]}
              >
                <Input placeholder="Custom slug" />
              </Form.Item>
            </Card>
            <div className="mb-10">
              <Card title="Tags" className="height-200">
                <Form.Item
                  className="inline-display mb-20 "
                  label="Tags"
                  name="tags"
                >
                  <Select mode="tags" placeholder="Tags">
                    {getTag.map(({ id: tagid = '', tag = '' }) => (
                      <Option key={tagid} value={tag}>
                        {tag}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Card>
            </div>
          </Col>
          <Col span={12}>
            <Card className="mb-20" title="Add UTMs tags">
              <Form.Item label="Campaign" name="utmCampaign">
                <Input placeholder="Campaign" />
              </Form.Item>
              <Form.Item label="Medium" name="utmMedium">
                <Input placeholder="Medium" />
              </Form.Item>

              <Form.Item label="Source" name="utmSource">
                <Input placeholder="Source" />
              </Form.Item>
              <Form.Item label="Content" name="utmContent">
                <Input placeholder="Content" />
              </Form.Item>

              <Form.Item label="Term" name="utmTerm" className="mb-0">
                <Input placeholder="Term" />
              </Form.Item>
            </Card>
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col span={24}>
            <Card className="mb-20" title="Add Notes">
              <Form.Item label="Notes" name="notes">
                <TextArea rows={6} placeholder="Description of your link" />
              </Form.Item>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card className="mb-20 meta-card-section" title={MetaSectionTitle}>
              <div className="d-flex  fill-width">
                <div
                  className={
                    imageUrl ? 'border-right left-section' : '  left-section'
                  }
                >
                  <Form.Item label="Title" name="ogTitle">
                    <Input onChange={handleTitleChange} placeholder="Title" />
                  </Form.Item>
                  <Form.Item
                    label="Description (Recommended 150 characters)"
                    name="ogDescription"
                  >
                    <TextArea
                      onChange={handleDescriptionChange}
                      autoSize={{
                        minRows: 4,
                        maxRows: 4
                      }}
                      showCount
                      maxLength={160}
                      placeholder="Description of your link"
                    />
                  </Form.Item>
                  <Form.Item name="ogImage" label="Image (1200 x 630)">
                    <Dragger
                      className={`${fileList?.length > 0 ? 'hide-border' : ''}`}
                      listType="picture-card"
                      beforeUpload={() => {
                        return false;
                      }}
                      showUploadList={{
                        showDownloadIcon: false,
                        showPreviewIcon: false,
                        showRemoveIcon: true
                      }}
                      fileList={fileList}
                      maxCount={1}
                      onChange={onChangeUpload}
                    >
                      <p>image</p>
                    </Dragger>
                  </Form.Item>
                </div>
                {imageUrl && (
                  <div className="right-section">
                    <div className=" fill-width d-flex justify-center">
                      <Card
                        className="meta-card"
                        cover={
                          <img
                            alt="meta-tag"
                            className="meta-img"
                            src={imageUrl}
                          />
                        }
                      >
                        <Meta title={title} description={description} />
                      </Card>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          </Col>
        </Row>

        <Card>
          <Row justify="end" gutter={10} align="middle">
            <Col>
              <Form.Item>
                <Button
                  htmlType="button"
                  className="btn-secondary-xl"
                  onClick={() => history?.goBack()}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  className="btn-Primary mb-0"
                  htmlType="submit"
                  loading={updateLoading || createtagLoading}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </>
  );
};

export default EditLink;
